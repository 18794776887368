<template>
  <b-card>
    <CondoFeeTable
      :isLoadingData="isLoadingData"
      :condominiumFees="condominiumFees"
      @get-condominal-fees="getCondominalFees"
    />

  </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import CondoFeeTable from '@/modules/owners/components/myProfits/CondoFeeTable'
import { showAlertMessage, stringAleatorio } from "@/helpers/helpers"

export default {
  components: {
    CondoFeeTable
  },
  async created() {
    await this.init()
  },
  props:{
    selectedContract:{
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      housingData: null,
      condominiumFees: {},
      isLoadingData: false,
      isUpdatingHousing: false,
      paymentTypes: [],
      selectedYear: new Date().getFullYear()
    }
  },
  watch: {
    myContracts: {
      deep: true,
      async handler() {
        await this.init()
      },
    },
  },
  computed:{
    ...mapState('market', ['hotels']),
    ...mapState('auth', ['myContracts', 'user']),
  },
  methods: {
    ...mapActions("owners", ["fetchHousing", "fetchCondoFeeHousingDetail"]),
    ...mapMutations("owners", ["setCondominiumFees", "setRoomsTypes"]),
    async init(){
      const idHousing = this.myContracts.contractSelected.housingid
      this.setCondominiumFees([])
      if(idHousing){
        // const housing = await this.fetchHousing({idHousing})
        // this.housingData = housing[0]
        // console.log(this.housingData)
        await this.getCondominalFees(this.selectedYear)
        // this.paymentTypes = await this.fetchPaymentMethodsFeeQuota()
      }
    },
    async getCondominalFees(year){
      this.isLoadingData = true
      const condominiumFees = await this.fetchCondoFeeHousingDetail({idHousing: this.myContracts?.contractSelected?.housingid, year, lang: this.user.languageCode, webOwners:1})
      const quarter = { 1 : 1, 2 : 1, 3 : 1, 4 : 1, 5 : 2, 6 : 2, 7 : 2, 8 : 2, 9 : 3, 10 : 3, 11 : 3, 12: 3 }
      condominiumFees?.details.forEach( condFee => {
        condFee.isSelectedItem = false
        condFee.isLoadingDetails = false
        condFee.quarter = quarter[condFee.monthCondoFeeHousing]
        condFee.detailsFee = []
      })
      this.setCondominiumFees(condominiumFees)
      this.condominiumFees = condominiumFees
      this.isLoadingData = false
    }
  },
}
</script>
<style>
.select-year{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1.5rem;
}

.select-year h2{
  font-size: 1.2rem;
  color: black;
}
</style>