<template>
  <b-card no-body>
    <b-table
      :items="datatable"
      responsive
      :fields="tableColumns"
      class="mb-0 position-relative"
      :tbody-tr-class="rowClass"
      show-empty
      :empty-text="$t('There are no records to show')"
      small
      sticky-header="40rem"
      :busy.sync="isLoadingMyProfits"
      no-border-collapse
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
          <strong> {{$t('loading')}}... </strong>
        </div>
      </template>

      <template #head()="data">
        <span class="custom-title" v-html="data.label.toUpperCase()"></span>
      </template>

      <template #cell()="data">
        <div class="half-margin">
          <span>{{ data.value }}</span>
        </div>
      </template>

      <template #head(accumulatedProfit)="data">
        <span v-html="data.label.toUpperCase()"></span> <label class="colindex">(a)</label>
      </template>

      <template #head(acumulatedFee)="data">
        <span v-html="data.label.toUpperCase()"></span> <label class="colindex">(b)</label>
      </template>

      <template #head(balance)="data">
        <span v-html="data.label.toUpperCase()"></span> <label class="colindex">(a-b)</label>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { utils } from '@/modules/owners/mixins/utils'
import { formatDate } from '@/helpers/helpers'

export default {
  mixins: [utils],
  props:{
    myProfits:{
      type: Array,
      required: true,
    },
    tableColumns:{
      type: Array,
      required: true,
    },
    sortBy:{
      type: String,
      required: true,
    },
    isSortDirDesc:{
      type: Boolean,
      required: true,
    },
    refInvoiceListTable:{
      type: Object,
      required: false,
    },
    isLoadingMyProfits:{
      type: Boolean,
      required: true
    }
  },
  data(){
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    datatable(){
      return this.myProfits.length > 0 ? this.myProfits : []
    }
  },
  methods:{
    formDate(date){
      return formatDate({date, language: this.user?.languageName})
    }
  }
}
</script>
<style scoped>
.half-margin{
  margin: 0.5rem 0;
}
.custom-title{
  width: 1rem;
}
.colindex{
  font-style: italic;
  color: red;
  text-transform: lowercase;
}
</style>