<template>
  <div style="padding: 1rem;">

    <b-card no-body>
      <b-card-body>

        <!-- <RequestReservations /> -->
        <b-tabs v-model="tabIndex">
          <b-tab @click="setTab(0, $t('condo fee'))">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span>{{ $t('condo fee')}} </span>
            </template>
            <CondoFee :selectedContract="myContracts.contractSelected"/>
          </b-tab>
          <b-tab @click="setTab(1, $t('Montly Report'))" v-if="canAccessExecutiveReports">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span> {{$t('Montly Report')}} </span>
            </template>
            <b-row>
              <b-col cols="12">
              <SkeletonMyProfitsTable v-if="isLoadingMyProfits"/>

              <div v-else>
                <Filters
                  :perPage="perPage"
                  :perPageOptions="perPageOptions"
                  :searchQuery="searchQuery"
                  @change-per-page="changePerPage"
                  @search-profit="getMyProfits"
                />
			
                <Table
                  class="capitalize"
                  :myProfits="myProfits"
                  :tableColumns="tableColumns"
                  :sortBy="sortBy"
                  :isSortDirDesc="isSortDirDesc"
                  :refProfitsListTable="refProfitsListTable"
                  :isLoadingMyProfits="isLoadingMyProfitsTable"
                />
                <PaginationTable
                  :currentPage="currentPage"
                  :totalRows="profits.myProfits.length"
                  :perPage="perPage"
                  @change-page="changePage"
                  v-if="profits.myProfits.length"
                />
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="setTab(2, $t('Yearly Report'))" v-if="canAccessExecutiveReports">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span>{{ $t('Yearly Report')}} </span>
            </template>
            <ExecutiveReports/>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
<!-- / Coming soon page-->
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import { utils } from '@/modules/owners/mixins/utils'

import Filters from '@/modules/owners/components/myProfits/Filters'
import Table from '@/modules/owners/components/myProfits/Table'
import PaginationTable from '@/modules/owners/components/myProfits/PaginationTable'
import ExecutiveReports from '@/modules/owners/components/myProfits/ExecutiveReports'

import SkeletonMyProfitsTable from '@/modules/owners/components/skeletons/SkeletonMyProfitsTable'
import { formatDate, toCurrency } from '@/helpers/helpers'
import CondoFee from '@/modules/owners/components/myProfits/CondoFee'


export default {
	mixins:[utils],
	components: {
		vSelect,
		
		Filters,
		Table,
		PaginationTable,
		SkeletonMyProfitsTable,
		ExecutiveReports,
    	CondoFee
	},
	async created(){
		await this.getInitialContentProfits()
		if (this.$route.params.tabIndex != null) {
			this.tabIndex = this.$route.params.tabIndex
		}
		// addDataUserConnected({page: this.$route.name})		
	},
	data() {
		return {
			perPage: 10,
			currentPage: 1,
			perPageOptions: [5, 10, 25, 50, 100],
			searchQuery: '',
			sortBy: 'id',
			isSortDirDesc: true,
			refProfitsListTable : null,
			isLoadingMyProfits: false,
			isLoadingMyProfitsTable: false,
			
			tabIndex: 0
		}
	},
	computed: {
		...mapState('owners', ['profits', 'selectedTabProfits']),
		...mapState('auth', ['user','myContracts']),
		myProfits(){
			const items = this.profits.myProfits
			return items?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage ) || []
		},
		canAccessExecutiveReports(){
			let validContract = false
			if(this.myContracts){
				const { returnType = false } = this.myContracts?.contractSelected
				validContract = returnType != null //&& returnType == 2
			}

			return validContract
		},
		tableColumns(){
			const usdText = 'USD'
			
			return[
				// { key: 'id', label: '#', sortable: true },
				// { key: 'operationNights',   label: this.$t('Nights under Hotel Operation'),  class: 'text-center'},
				{ key: 'contract',        	label: this.$t('contract'),           	    class: 'text-center'},
				{ key: 'dateInit',        	label: this.$t('month'),           				class: 'text-center', formatter: value => {return this.formDate(value, 'MMMM')}},
				{ key: 'usedNights',      	label: this.$t('Nights by owner'), 				class: 'text-center'},
				{ key: 'profit',			label: this.$t('Rental Pool Profit'), 			class: 'text-center', formatter: value => {return value ? toCurrency(parseFloat(value)) +' '+ usdText: 'N/A'}},
				{ key: 'accumulatedProfit', label: this.$t('Accumulated profit'),     		class: 'text-center', formatter: value => {return value ? toCurrency(parseFloat(value)) +' '+ usdText: 'N/A'}},
				{ key: 'fee',  				label: this.$t('Condomium fee'),   				class: 'text-center', formatter: value => {return value ? toCurrency(parseFloat(value)) +' '+ usdText: 'N/A'}},
				{ key: 'acumulatedFee',  	label: this.$t('Accumulated condomium fee'),	class: 'text-center', formatter: value => {return value ? toCurrency(parseFloat(value)) +' '+ usdText : 'N/A'}},
				{ key: 'balance', 			label: this.$t('Yield after fee'),     			class: 'text-center', formatter: value => {return value ? toCurrency(parseFloat(value)) +' '+ usdText : 'N/A'}}

			]
		}
	},
	methods:{
		...mapActions('owners', ['fetchMonthlyProfit','pushActionsService']),		
		...mapMutations('owners', ['setMyProfits', 'setSelectedTabProfits']),
		async getInitialContentProfits(){
			this.isLoadingMyProfits = true
			this.tabIndex = this.selectedTabProfits
			if( !this.profits.myProfits.length ) await this.getMyProfits(new Date().getFullYear())
			this.isLoadingMyProfits = false
		},
		async getMyProfits(selectedYear){
			const { id } = this.myContracts.contractSelected // id del contrado seleccionado
			this.isLoadingMyProfitsTable = true
			const profits = await this.fetchMonthlyProfit({idContract:id, year: selectedYear}) // TODO: cambiar con el id del contracto seleccionado
			if (profits) this.setMyProfits(profits)
			else this.setMyProfits([])
			this.isLoadingMyProfitsTable = false
		},
		changePage(page){
			this.currentPage = page
		},
		changePerPage(perPage){
			this.perPage = perPage
		},
		async setTab(index, infoTab){
			this.setSelectedTabProfits(index)
			await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr
		},
		formDate(date, toFormat){
			return formatDate({date, language: this.user?.languageName, toFormat})
		},		
		
	},
	watch: {
		myContracts: {
		deep: true,
		async handler() {
			this.isLoadingMyProfits = true
			await this.getMyProfits(new Date().getFullYear())
			this.isLoadingMyProfits = false
			// addDataUserConnected({page: this.$route.name})
		},
		},
	},
}
</script>
<style scoped>
.capitalize{
  text-transform: capitalize !important;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>