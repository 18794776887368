<template>
  <b-row class="p-1 pt-0 mt-0">
    <b-col md="2">
      <label>{{$t('Search by year')}}</label>
      <v-select
        v-model="searchForm.year"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="yearsBefore"
        :clearable="false"
        class="per-page-selector"
        :placeholder="$t('Select a year')"
        @input="searchProfitPerYear"
      />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props:{
    perPage:{
      type: Number,
      required: true,
    },
    perPageOptions:{
      type: Array,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      porPagina: this.perPage,
      statusFilter: null,
      searchForm: {
        year: new Date().getFullYear()
      }
    }
  },
  computed: {
    yearsBefore(){
      const amount = 2
      let years= []
      for (let i = 0; i < amount; i++) {
        years.push(new Date().getFullYear() - i)
      }
      return years
    }
  },
  methods:{
    changePerPage( perPage ){
      this.$emit('change-per-page', perPage)
      this.porPagina = perPage
    },
    searchProfitPerYear(){
      this.$emit('search-profit', this.searchForm.year)
    }
  }
}
</script>